/*
 * Nav Dropdowns
 *
 * runs the functionality of clicking the arrow in the mobile menu dropdown
 */

function navMob($) {
	const selector = '.js-nav-drop';
	const activeClass = 'is-active';

	function toggleDropdown(element) {
		const isActive = $(element)
			.toggleClass('m-nav__toggle-wrapper--active')
			.hasClass(activeClass);

		$(element).toggleClass('m-nav__toggle-wrapper--active');
		$(element)
			.toggleClass(activeClass)
			.next('ul')
			.slideToggle(250);
		$(element)
			.children('.js-plus-toggle')
			.toggleClass('a-plus-toggle--opened');

		// Find the sibling <a> tag and update its aria-expanded attribute
		const navItem = $(element).siblings('a');
		if (navItem.length > 0) {
			// Check if the sibling <a> tag exists
			navItem.attr('aria-expanded', isActive ? 'false' : 'true');
		}
	}

	$(selector).on('click keydown', function(event) {
		if (
			event.type === 'click' ||
			(event.type === 'keydown' && event.key === 'Enter')
		) {
			toggleDropdown(this);
		}
	});
}

export default navMob;
