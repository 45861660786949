class SibWebApiForm {
	// Captcha Site Key
	#captchaSiteKey;
	// Action of for the Ajax request
	#ajaxAction;
	// Nonce code for the current action
	#ajaxNonce;
	// URL for sending Ajax requests
	#ajaxUrl;
	// Captcha token that we receive from sending request to gcaptcha service. It's being used for captcha verification on back side
	#captchaToken;
	// A key under which captcha token will be written
	#captchaFieldKey;
	// Flag that is true when Ajax request is being sent and processed
	#isSendingAjax;
	// Current data of the form
	#formData;
	// Unique ID for captcha script tag
	#captchaScriptTagId;

	constructor(form) {
		this.form = form;

		this.#initProps();
		this.#initElements();
		this.#initListeners();
		this.#addCaptchaScript();
	}

	/**
	 * Adding script tag for including gcaptcha script from remote
	 */
	#addCaptchaScript() {
		// Checking existence of this tag and avoid double including
		const scriptTags = document.querySelector(
			`${this.#captchaScriptTagId}`,
		);
		if (scriptTags) {
			return;
		}

		let script = document.createElement('script');
		script.setAttribute(
			'src',
			`https://www.google.com/recaptcha/api.js?render=${
				this.#captchaSiteKey
			}`,
		);
		script.setAttribute('async', 'async');
		script.setAttribute('defer', 'async');
		script.setAttribute('id', this.#captchaScriptTagId);
		document.body.appendChild(script);
	}

	/**
	 * Setting initial values for properties
	 */
	#initProps() {
		// eslint-disable-next-line no-secrets/no-secrets
		this.#captchaSiteKey = '6LcWEKsgAAAAAFF_buu1cyO9gFh63bQ921jBdpdK';
		// eslint-disable-next-line no-undef
		this.#ajaxAction = sibWebApi.action;
		// eslint-disable-next-line no-undef
		this.#ajaxNonce = sibWebApi.nonce;
		// eslint-disable-next-line no-undef
		this.#ajaxUrl = sibWebApi.url;
		// eslint-disable-next-line no-undef
		this.#captchaFieldKey = sibWebApi.captchaFieldKey;
		this.formLoadingClasses = [
			'before:opacity-80',
			'before:pointer-events-auto',
		];
		this.formDefaultClasses = [
			'before:opacity-0',
			'before:pointer-events-none',
		];
		this.#isSendingAjax = false;
		this.#formData = null;
		this.#captchaScriptTagId = 'sibWebApiCaptchaScript';
	}

	/**
	 * Define some elements that will be needed
	 */
	#initElements() {
		this.responseMessageElement = this.form.querySelector(
			`[data-role='response-message']`,
		);
	}

	/**
	 * Defining events listeners
	 */
	#initListeners() {
		this.form.addEventListener('submit', this.#formHandler.bind(this));
	}

	/**
	 * Updating current token value
	 *
	 * @param token
	 */
	#setCaptchaToken(token) {
		this.#captchaToken = token;
	}

	/**
	 * This function is being executed once the main form is sent
	 *
	 * @param event
	 */
	#formHandler(event) {
		event.preventDefault();

		if (this.#isSendingAjax) {
			return;
		}

		// Getting current token
		// eslint-disable-next-line no-undef
		grecaptcha.ready(() => {
			// eslint-disable-next-line no-undef
			grecaptcha
				.execute(this.#captchaSiteKey, {
					action: 'create_comment',
				})
				.then(token => {
					this.#setCaptchaToken(token);
					this.#sendRequest();
				});
		});
	}

	/**
	 * Sending Ajax request to the back side and processing the response
	 */
	#sendRequest() {
		// Update current form data before sending it to backend
		this.#updateFormData();

		// eslint-disable-next-line jquery/no-ajax
		$.ajax({
			type: 'POST',
			url: this.#ajaxUrl,
			data: this.#formData,
			beforeSend: () => {
				if (this.responseMessageElement) {
					jQuery(this.responseMessageElement).slideUp();
				}
				this.#showLoader();
				this.#isSendingAjax = true;
			},
			success: response => {
				const responseMessage = response.data.message;
				if (this.responseMessageElement) {
					this.responseMessageElement.innerHTML = responseMessage;
					jQuery(this.responseMessageElement).slideDown();
				}
			},
			complete: () => {
				this.#hideLoader();
				this.#isSendingAjax = false;
			},
			error: xhr => {
				this.#hideLoader();
				this.#isSendingAjax = false;
				console.log('error:', xhr);
			},
		});
	}

	/**
	 * Updating value of formData, grabbing all the fields values and adding some needed items
	 */
	#updateFormData() {
		let formData = new FormData(this.form);
		formData.append('action', this.#ajaxAction);
		formData.append('_nonce', this.#ajaxNonce);
		formData.append(this.#captchaFieldKey, this.#captchaToken);

		const data = {};
		formData.forEach(function(value, key) {
			const prev = data[key];
			if (prev) {
				data[key] = `${prev}, ${value}`;
			} else {
				data[key] = value;
			}
		});

		this.#formData = data;
	}

	/**
	 * Showing Loader above the form block and making form disabled
	 */
	#showLoader() {
		this.form.classList.remove(...this.formDefaultClasses);
		this.form.classList.add(...this.formLoadingClasses);
	}

	/**
	 * Hiding Loader
	 */
	#hideLoader() {
		this.form.classList.remove(...this.formLoadingClasses);
		this.form.classList.add(...this.formDefaultClasses);
	}
}

function sibWebApiForm() {
	const forms = document.querySelectorAll(`form[data-role='sibwebapi-form']`);
	[...forms].map(block => {
		new SibWebApiForm(block);
	});
}

export default sibWebApiForm;
