import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function swiperSlider() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	// Ensuring we don't show buttons if there is just 1 entry outputted
	if ($('.o-slider-1__stories-slide-item ').length <= 1) {
		$('.o-slider-1__stories-slider-wrapper').addClass(
			'o-slider-1__stories-slider-wrapper--disabled',
		);
	} else {
		$('.o-slider-1__stories-slider-wrapper').removeClass(
			'o-slider-1__stories-slider-wrapper--disabled',
		);

		$('.o-slider-1__button-pagination-wrapper').removeClass('hidden');

		new Swiper('.js-slider-swiper-slider', {
			loop: true,
			speed: 600,
			autoHeight: true,
			autoplay: {
				delay: 7000,
			},
			slidesPerView: 1,
			spaceBetween: 48,
			navigation: {
				prevEl: '.o-slider-1__button-pagination--prev',
				nextEl: '.o-slider-1__button-pagination--next',
			},
		});
	}
}

export default swiperSlider;
