function addClassVanillaJs(elements, className, exclude = false) {
	elements.forEach(function(el) {
		if (!exclude || (exclude && el != exclude)) {
			el.classList.add(className);
		}
	});
}

function removeClassVanillaJs(elements, className, exclude = false) {
	elements.forEach(function(el) {
		if (!exclude || (exclude && el != exclude)) {
			el.classList.remove(className);
		}
	});
}

export { addClassVanillaJs, removeClassVanillaJs };
