import Swiper, { Autoplay, EffectFade } from 'swiper';

function hero4() {
	Swiper.use([Autoplay, EffectFade]);

	new Swiper(`.o-hero-type-4__repeater`, {
		autoplay: {
			delay: 2000,
			disableOnInteraction: false,
		},
		speed: 600,
		effect: 'fade',
		slidesPerView: 1,
		spaceBetween: 0,
	});
}

export default hero4;
