// Import the MixItUp library and two custom plugins, mixitupMultifilter and mixitupPagination.
import AOS from 'aos';
import mixitup from 'mixitup';

import Helpers from '../../helpers/helpers';
import mixitupMultifilter from '../../vendors/mixitup/mixitup-multifilter';
import mixitupPagination from '../../vendors/mixitup/pagination';

/**
 * Filters and paginates items in the .mixer-container using MixItUp library.
 *
 * The function starts by checking if the required elements, `.mixer-container` and `.js-filter-options`, are present on the page.
 * If either of these elements are missing, the function immediately returns and does not run any further.
 *
 * Next, the function sets up MixItUp by including two custom plugins, `mixitupPagination` and `mixitupMultifilter`, and configures MixItUp
 * to use these plugins. The configuration object sets the limits of the pagination to 9 items per page, sets the logic for filtering to be
 * "or" within groups and "and" between groups, and sets the selectors for targets and controls.
 *
 * Event listeners are then added to the filter panel toggle button and filter reset button. The toggle button opens and closes the filter
 * panel, and the reset button resets all sorting and filtering settings.
 *
 * @returns {undefined}
 */
export default function() {
	// Use the mixitupPagination and mixitupMultifilter plugins with MixItUp
	mixitup.use(mixitupPagination);
	mixitup.use(mixitupMultifilter);

	// selectors to reach elements in module
	const selector = {
		block: '.js-filter',
		item: '.js-filter-item',
		control: '.js-filter-control',
		optionsPanel: '.js-filter-options-panel',
		toggleBtn: '.js-filter-panel-toggle-btn',
		dropdown: '.js-filter-dropdown',
		dropdownToggler: '.js-dropdown-toggler',
		resetBtn: '.js-filter-options-reset',
		paginationContainer: '.js-filter-pagination-container',
		paginationBtn: '.mixitup-control',
		nothingFoundCard: '.js-nothing-found ',
		searchInput: '.js-search-input',
		itemTitle: '.js-item-title',
	};
	// active state classes
	const activeStates = {
		controlActive: 'mixitup-control-active',
		dropdownActive: 'mixitup-dropdown-active',
	};

	/**
	 * Initializes MixItUp plugin on each block element and binds click events to filter and pagination buttons.
	 * @param {string} blockSelector - The selector for the block element
	 */
	const initializeMixItUp = blockSelector => {
		document.querySelectorAll(blockSelector).forEach(container => {
			const mixer = initMixer(container);

			container.addEventListener('click', e => {
				const reset = e.target.closest(selector.resetBtn) ?? null;
				if (reset) {
					resetMixerState(container, mixer);
					return;
				}

				const pagination =
					e.target.closest(selector.paginationBtn) ?? null;
				if (pagination) {
					triggerPagination(pagination, mixer);
					return;
				}

				// Set Sort parametr in url
				const sortButton = e.target.closest(selector.control);
				if (sortButton) {
					const sortValue = sortButton.getAttribute('data-sort');
					if (sortValue) {
						const urlParams = new URLSearchParams(
							window.location.search,
						);
						urlParams.set('sort', sortValue);
						const newUrl = `${window.location.origin}${
							window.location.pathname
						}?${urlParams.toString()}`;
						window.history.replaceState(null, '', newUrl);
					}
					return;
				}
			});

			// Debounce function and event listener for the search input
			let debounceTimeout;
			function debounce(func, delay) {
				clearTimeout(debounceTimeout);
				debounceTimeout = setTimeout(() => {
					func();
				}, delay);
			}

			// Select the search input element
			const searchInput = document.querySelector(selector.searchInput);

			// Add event listener for keyup events on the search input
			searchInput.addEventListener('keyup', event => {
				// Get the text entered in the search input
				const searchText = event.target.value;
				// Execute the debounce function with the filtering logic
				debounce(() => {
					if (searchText.length > 0) {
						// Call the filterItemsBySearch function when there's text in the search input
						filterItemsBySearch(searchText, mixer);
					} else {
						// If search input is empty, show all items
						const mixer = mixitup(container);
						mixer.filter('all');
					}
				}, 200);
			});

			// trigger Event MixItUp has been initialized
			customEventOnMixInitialized(mixer.getState());
		});
	};

	/**
	 * @param {HTMLElement} container Filter container
	 * @return {object} mixitup
	 **/
	function initMixer(container) {
		const params = Helpers.getJsonFromAttr(container.dataset.filter);

		// Initial state
		const urlParams = new URLSearchParams(window.location.search);
		const defaultsortValue = urlParams.get('sort');
		const loadState = {
			sort: 'name:asc',
		};

		// Collect chosen types from URL
		const initiallyChosenTypes = getTypesFromURL(container, true);
		// Set initial filter values if we have them
		if (initiallyChosenTypes.length > 0) {
			loadState.filter = initiallyChosenTypes.join(',');
		}

		// Collect chosen locations from URL
		const initiallyChosenTopics = getTopicsFromURL(container, true);
		// Set initial filter values if we have them
		if (initiallyChosenTopics.length > 0) {
			loadState.filter = initiallyChosenTopics.join(',');
		}

		// Collect chosen languages from URL
		const initiallyChosenLangueages = getLanguagesFromURL(container, true);
		// Set initial filter values if we have them
		if (initiallyChosenLangueages.length > 0) {
			loadState.filter = initiallyChosenLangueages.join(',');
		}

		// Mixer config
		// Configure MixItUp with the pagination and multiFilter options
		let config = {
			load: loadState,
			multifilter: {
				enable: true,
				logicWithinGroup: 'or',
				logicBetweenGroups: 'and',
			},
			controls: {
				scope: 'local',
			},
			pagination: {
				limit: 24,
				hidePageListIfSinglePage: true,
				generatePageStats: false,
			},
			selectors: {
				target: selector.item,
				control: selector.control,
				pageList: selector.paginationContainer,
			},
			animation: {
				effects: 'fade translateX',
				duration: 300,
				nudge: false,
			},
			callbacks: {
				// Event for Mixer start sorting
				onMixStart: function(state, futureState) {
					changePaginationIcons(state.container);
					// Perhaps we need to remove an 'empty' card
					updateEmptyCardVisibility(state, state.container);
					// trigger custom event on onMixStart
					customEventOnMixStart(state, futureState);
				},
				// Event for Mixer stop sorting
				onMixEnd: function(state) {
					// toggle active state for dropDown
					dropdownActive(state.container);
					updateURL(state, container);
					// set data-nth attribute
					setCardsDataPosition(state);
					// refresh AOS Animation
					AOS.refresh();
					// Perhaps we need to show an 'empty' card
					updateEmptyCardVisibility(state, state.container);
					scrollToBlockTop(state.container);
					// trigger custom event on onMixEnd
					customEventOnMixEnd(state);
				},
				// Callback for pagination method start
				onPaginateStart: function(state) {
					scrollToBlockTop(state.container);
				},
			},
		};

		// override default settings
		config = deepMerge(config, params);

		// Initialize MixItUp with the container and config options
		const mixer = mixitup(container, config);
		/**
		 * Sets the active filters forcefully.
		 * @bug When loaded with existing filters and a new filter is clicked, the default filters are broken.
		 */
		if (initiallyChosenTypes.length > 0) {
			mixer.setFilterGroupSelectors('type', initiallyChosenTypes);
			mixer.parseFilterGroups();
		}

		if (initiallyChosenTopics.length > 0) {
			mixer.setFilterGroupSelectors('topic', initiallyChosenTopics);
			mixer.parseFilterGroups();
		}

		if (initiallyChosenLangueages.length > 0) {
			mixer.setFilterGroupSelectors(
				'language',
				initiallyChosenLangueages,
			);
			mixer.parseFilterGroups();
		}

		// Perhaps we need to set some initial state or styles
		setInitialState(mixer, container);

		if (defaultsortValue) {
			const sort_button = $(`button[data-sort="${defaultsortValue}"]`);
			if (sort_button.length > 0) {
				sort_button.click();
			}
		}

		return mixer;
	}

	/**
	 * Set initial state for the mixer. For some reasons there is no proper callback for such a case :|
	 * @param mixer
	 * @param container
	 */
	function setInitialState(mixer, container) {
		// Current state of a mixer
		const state = mixer.getState();

		// If no cards found for the current query, show an 'empty' card
		updateEmptyCardVisibility(state, container);
		// Set default pagination icons
		changePaginationIcons(container);
		// Highlight dropdowns if it has default values
		dropdownActive(container);
		// set data-nth attribute
		setCardsDataPosition(state);
	}

	/**
	 * Show 'empty' card if there are no cards found for the current state
	 * @param state
	 * @param container
	 */
	function updateEmptyCardVisibility(state, container) {
		// show/hide NothingFound card according to the current query results
		const nothingFoundCard = container.querySelector(
			selector.nothingFoundCard,
		);
		if (!nothingFoundCard) {
			return;
		}
		if (state.totalMatching) {
			nothingFoundCard.classList.add('hidden');
		} else {
			nothingFoundCard.classList.remove('hidden');
		}
	}

	/**
	 * reset Mixer State to default
	 * @param {HTMLElement} container Filter container
	 * @param {object} mixer MixItUp object
	 * @return void
	 **/
	function resetMixerState(container, mixer) {
		// sort items according to its initial state
		mixer.sort(mixer.getConfig().load.sort);
		// paginate to 1st page if needed
		const state = mixer.getState();
		if (state.totalPages > 1) {
			mixer.paginate(1);
		}
		const base_url = new URL(window.location.href);
		base_url.search = '';
		const reset_base_url = base_url.toString();
		window.history.replaceState(null, '', reset_base_url);
	}

	/**
	 * Change default Pagination Icons
	 * @param {HTMLElement} container Filter container
	 * @return void
	 **/
	function changePaginationIcons(container) {
		const buttonPrev = container.querySelector('.mixitup-control-prev');
		const buttonNext = container.querySelector('.mixitup-control-next');
		const buttonPrevTemplateIcon = container.querySelector(
			'.js-template-pagination-left-icon',
		);
		const buttonNextTemplateIcon = container.querySelector(
			'.js-template-pagination-right-icon',
		);

		if (buttonPrev && buttonPrevTemplateIcon) {
			let icon = buttonPrevTemplateIcon.content.cloneNode(true);
			buttonPrev.innerHTML = '';
			buttonPrev.appendChild(icon);
		}
		if (buttonNext && buttonNextTemplateIcon) {
			let icon = buttonNextTemplateIcon.content.cloneNode(true);
			buttonNext.innerHTML = '';
			buttonNext.appendChild(icon);
		}
	}

	/**
	 * Click Event for Pagination buttons.
	 * For some reason default events are not working
	 * @param {HTMLElement} button MixItUp pagination button
	 * @param {object} mixer MixItUp object
	 * @return void
	 */
	function triggerPagination(button, mixer) {
		const pageNumber = button.dataset.page;
		switch (pageNumber) {
			case 'prev':
				mixer.prevPage();
				break;
			case 'next':
				mixer.nextPage();
				break;
			default:
				mixer.paginate(Number(pageNumber));
				break;
		}
	}

	/**
	 * set active state for DropDown Toggler
	 * @param {HTMLElement} container Filter container
	 * @return void
	 */
	function dropdownActive(container) {
		// Set active class for Buttons Dropdown
		const control = container.querySelectorAll(selector.dropdown);

		control.forEach(function(item) {
			// active class is added with small delay, in order MixItUp put proper classes
			setTimeout(function() {
				const activeBtns = item.querySelectorAll(
					`.${activeStates.controlActive}`,
				);

				const dropDownBtn = item.querySelector(
					selector.dropdownToggler,
				);

				if (activeBtns.length) {
					dropDownBtn.classList.add(activeStates.dropdownActive);
				} else {
					dropDownBtn.classList.remove(activeStates.dropdownActive);
				}
			}, 100);
		});
	}

	/**
	 * Scroll to the Block start
	 * @param {HTMLElement} container Filter container
	 * @return void
	 */
	function scrollToBlockTop(container) {
		const block = container.closest(selector.block);
		const offsetlTop = block.getBoundingClientRect().top;

		if (offsetlTop < 0) {
			// scrollTop to Block start
			window.scrollTo({
				top: offsetlTop + window.scrollY - 150,
				left: 0,
				behavior: 'smooth',
			});
		}
	}

	/**
	 * Updates the current page's URL by adding new GET params according to the current chosen filters values ('type', 'location' ,'annum','cat' for now)
	 * @param state
	 */
	function updateURL(state) {
		// Clicked button
		const button = state.triggerElement;
		// Do not need to listen other buttons than
		if (!button || !button.matches(`[data-slug][data-toggle]`)) {
			return;
		}
		const chosenTypes = getChosenTypes(state.container);

		const chosenTopics = getChosenTopics(state.container);

		const chosenLanguages = getChosenLanguages(state.container);

		// Get the current URL
		let url = new URL(window.location.href);

		// If no types chosen - get rid of the type GET param
		if (chosenTypes.length < 1) {
			url.searchParams.delete('type');
		} else {
			// Otherwise, create a new GET param value

			// Join the array of chosen types into a comma-separated string
			let chosenTypesString = chosenTypes.join(',');

			// Set the new type query parameter
			url.searchParams.set('type', chosenTypesString);
		}

		// If no location chosen - get rid of the type GET param
		if (chosenTopics.length < 1) {
			url.searchParams.delete('topic');
		} else {
			// Otherwise, create a new GET param value

			// Join the array of chosen types into a comma-separated string
			let chosenTopicsString = chosenTopics.join(',');

			// Set the new location query parameter
			url.searchParams.set('topic', chosenTopicsString);
		}

		if (chosenLanguages.length < 1) {
			url.searchParams.delete('language');
		} else {
			// Otherwise, create a new GET param value

			// Join the array of chosen types into a comma-separated string
			let chosenLanguagesString = chosenLanguages.join(',');

			// Set the new type query parameter
			url.searchParams.set('language', chosenLanguagesString);
		}

		// Replace the current URL with the updated URL
		window.history.replaceState({}, '', url);
	}

	/**
	 * Returns an array of chosen types according to the current query params
	 * @param container
	 * @param { boolean } toggleValues - if true, the function returns an array of data-toggle attribute values instead of types slugs
	 * @return {string[]|*[]}
	 */
	function getTypesFromURL(container, toggleValues = false) {
		let url = new URL(window.location.href);

		// Get the value of the "param1" parameter from the URL
		let types = url.searchParams.get('type');
		if (!types) {
			return [];
		}

		if (!toggleValues) {
			return types.split(',');
		}

		return types
			.split(',')
			.filter(typeSlug => {
				return container.querySelector(
					`[data-slug="${typeSlug}"][data-toggle]`,
				);
			})
			.map(typeSlug => {
				return container.querySelector(
					`[data-slug="${typeSlug}"][data-toggle]`,
				).dataset.toggle;
			});
	}

	/**
	 * Returns an array of chosen types' slugs within the passed container element
	 * @param container
	 * @return {*[]}
	 */
	function getChosenTypes(container) {
		// Get the types filters dropdown
		const typesDropdown = container.querySelector(
			`${selector.dropdown}[data-dropdown-type="type"]`,
		);
		if (!typesDropdown) {
			return [];
		}

		// Collect all the chosen types slugs into an array
		const chosenTypes = [];
		typesDropdown
			.querySelectorAll('.mixitup-control-active')
			.forEach(activeButton => {
				const typeSlug = activeButton.dataset.slug ?? '';
				if (typeSlug) {
					chosenTypes.push(typeSlug);
				}
			});

		return chosenTypes;
	}

	/**
	 * Returns an array of chosen locations according to the current query params
	 * @param container
	 * @param { boolean } toggleValues - if true, the function returns an array of data-toggle attribute values instead of locations slugs
	 * @return {string[]|*[]}
	 */
	function getTopicsFromURL(container, toggleValues = false) {
		let url = new URL(window.location.href);

		// Get the value of the "param1" parameter from the URL
		let topics = url.searchParams.get('topic');
		if (!topics) {
			return [];
		}

		if (!toggleValues) {
			return topics.split(',');
		}

		return topics
			.split(',')
			.filter(topicSlug => {
				return container.querySelector(
					`[data-slug="${topicSlug}"][data-toggle]`,
				);
			})
			.map(topicSlug => {
				return container.querySelector(
					`[data-slug="${topicSlug}"][data-toggle]`,
				).dataset.toggle;
			});
	}

	/**
	 * Returns an array of chosen locations' slugs within the passed container element
	 * @param container
	 * @return {*[]}
	 */
	function getChosenTopics(container) {
		// Get the locations filters dropdown
		const topicsDropdown = container.querySelector(
			`${selector.dropdown}[data-dropdown-type="topic"]`,
		);
		if (!topicsDropdown) {
			return [];
		}

		// Collect all the chosen location slugs into an array
		const chosenTopics = [];
		topicsDropdown
			.querySelectorAll('.mixitup-control-active')
			.forEach(activeButton => {
				const topicSlug = activeButton.dataset.slug ?? '';
				if (topicSlug) {
					chosenTopics.push(topicSlug);
				}
			});

		return chosenTopics;
	}

	// Language

	function getLanguagesFromURL(container, toggleValues = false) {
		let url = new URL(window.location.href);

		// Get the value of the "param1" parameter from the URL
		let languages = url.searchParams.get('language');
		if (!languages) {
			return [];
		}

		if (!toggleValues) {
			return languages.split(',');
		}

		return languages
			.split(',')
			.filter(languageSlug => {
				return container.querySelector(
					`[data-slug="${languageSlug}"][data-toggle]`,
				);
			})
			.map(languageSlug => {
				return container.querySelector(
					`[data-slug="${languageSlug}"][data-toggle]`,
				).dataset.toggle;
			});
	}

	/**
	 * Returns an array of chosen types' slugs within the passed container element
	 * @param container
	 * @return {*[]}
	 */
	function getChosenLanguages(container) {
		// Get the languages filters dropdown
		const languageDropdown = container.querySelector(
			`${selector.dropdown}[data-dropdown-type="language"]`,
		);

		if (!languageDropdown) {
			return [];
		}

		// Collect all the chosen languages slugs into an array
		const chosenLanguages = [];
		languageDropdown
			.querySelectorAll('.mixitup-control-active')
			.forEach(activeButton => {
				const languageSlug = activeButton.dataset.slug ?? '';
				if (languageSlug) {
					chosenLanguages.push(languageSlug);
				}
			});

		return chosenLanguages;
	}

	/**
	 * Filters items based on the search input value and displays matching items.
	 * @param {string} searchText - The text entered in the search input.
	 * @return {HTMLElement[]} - Array of matching items.
	 */
	function filterItemsBySearch(searchText) {
		// Convert search input to lowercase and trim extra spaces
		const lowerCaseSearchText = searchText.toLowerCase().trim();

		// Iterate through each container block
		document.querySelectorAll(selector.block).forEach(container => {
			// Get all items within the container
			const items = container.querySelectorAll(selector.item);
			const matchingItems = []; // Store items that match the search

			// Loop through each item in the container
			items.forEach(item => {
				// Find all titles within the item
				const titles = item.querySelectorAll(selector.itemTitle);
				let isVisible = false; // Flag to track if item matches search text

				// Check each title for a match with the search text
				titles.forEach(title => {
					const itemText = title.textContent.trim().toLowerCase();
					if (itemText.includes(lowerCaseSearchText)) {
						isVisible = true; // Set visibility flag to true if a match is found
					}
				});

				// Add the item to the matchingItems array if it contains a matching title
				if (isVisible) {
					matchingItems.push(item);
				}
			});

			// Apply MixItUp filtering to display only matching items in the container
			const mixer = mixitup(container);
			mixer.filter(matchingItems);
		});
	}

	/**
	 * Set data-nth attribute to visible cards
	 * @param {object} state
	 * @return void
	 */
	function setCardsDataPosition(state) {
		const cards = state.targets,
			showCards = state.show;

		cards.forEach(function(item) {
			item.setAttribute('data-nth', '');
		});

		if (showCards.length) {
			showCards.forEach(function(item, index) {
				item.setAttribute('data-nth', index + 1);
			});
		}
	}

	/**
	 * Custom Event that is triggered when MixItUp initialized
	 * to add custom handler: document.addEventListener('dod.mixitup.mixInitialized', __function__ );
	 * @param {object} state
	 * return void
	 */
	function customEventOnMixInitialized(state) {
		const eventOnMixInitialized = new CustomEvent(
			'dod.mixitup.mixInitialized',
			{
				detail: {
					state: state,
				},
			},
		);
		document.dispatchEvent(eventOnMixInitialized);
	}

	/**
	 * Custom Event that is triggered when MixItUp animation ended
	 * to add custom handler: document.addEventListener('dod.mixitup.mixInitialized', __function__ );
	 * @param {object} state
	 * return void
	 */
	function customEventOnMixEnd(state) {
		const eventOnMixEnd = new CustomEvent('dod.mixitup.mixEnd', {
			detail: {
				state: state,
			},
		});
		document.dispatchEvent(eventOnMixEnd);
	}

	/**
	 * Custom Event that is triggered when MixItUp before animation started
	 * to add custom handler: document.addEventListener('dod.mixitup.mixInitialized', __function__ );
	 * @param {object} state
	 * @param {object} futureState
	 * return void
	 */
	function customEventOnMixStart(state, futureState) {
		const eventOnMixStart = new CustomEvent('dod.mixitup.mixStart', {
			detail: {
				state: futureState,
			},
		});
		document.dispatchEvent(eventOnMixStart);
	}

	/**
	 * Performs a deep merge of objects.
	 * @param {Object} target - The target object to merge into.
	 * @param {...Object} sources - The source objects to merge from.
	 * @returns {Object} - The merged object.
	 */
	function deepMerge(target, ...sources) {
		// If no sources are left, return the merged target object
		if (!sources.length) {
			return target;
		}

		// Take the first source object
		const source = sources.shift();

		// Check if both the target and source are objects
		if (isObject(target) && isObject(source)) {
			// Iterate over each key in the source object
			for (const key in source) {
				// Check if the value is an object
				if (isObject(source[key])) {
					// If the key exists in the target but is not an object, assign an empty object to it
					if (!target[key]) {
						Object.assign(target, { [key]: {} });
					}
					// Recursively merge the nested objects
					deepMerge(target[key], source[key]);
				} else {
					// If the value is not an object, assign it directly to the target object
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		// Recursively merge the remaining sources
		return deepMerge(target, ...sources);
	}

	/**
	 * Checks if a given item is an object (excluding arrays and null).
	 * @param {*} item - The item to check.
	 * @returns {boolean} - True if the item is an object, false otherwise.
	 */
	function isObject(item) {
		return item && typeof item === 'object' && !Array.isArray(item);
	}

	// Initialize MixItUp on blocks
	initializeMixItUp(selector.block);

	function resetFilter() {
		$('.js-filter-options-reset').click();
	}

	// Function for checking if there are results after page loaded
	function checkHiddenAndClickReset() {
		$(document).ready(function() {
			setTimeout(function() {
				if (!$('.js-nothing-found').hasClass('hidden')) {
					resetFilter();
				}
			}, 2000);
		});
	}
	function resetBtnClicked() {
		$(document).on('click', '.js-nothing-found .a-btn__btn-text', function(
			event,
		) {
			event.preventDefault();
			resetFilter();
		});
	}

	resetBtnClicked();

	checkHiddenAndClickReset();
}
