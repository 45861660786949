import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

let swiperInstance;

function initSwiper() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	swiperInstance = new Swiper('.js-slider-swiper-testimonials', {
		speed: 600,
		autoplay: {
			delay: 7000,
		},
		centeredSlides: true,
		slidesPerView: 'auto',
		loop: true,
		initialSlide: 2,
		spaceBetween: 20,
		updateOnWindowResize: false,
		navigation: {
			prevEl: '.js-slider-swiper-testimonials-btn-prev',
			nextEl: '.js-slider-swiper-testimonials-btn-next',
		},
		pagination: {
			el: '.js-slider-swiper-testimonials-pagination',
			type: 'bullets',
			clickable: true,
		},
	});
}

function reinitSwiper() {
	if (swiperInstance) {
		swiperInstance.destroy(true, true);
	}
	initSwiper();
}

function sliderTestimonials() {
	initSwiper();

	let resizeTimeout;
	window.addEventListener('resize', () => {
		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(reinitSwiper, 300);
	});
}

export default sliderTestimonials;
