function showAccordion() {
	const componentClass = 'm-accordion';

	$('.js-accordion').click(function(e) {
		e.preventDefault();

		$(this)
			.parents(`.${componentClass}__group`)
			.toggleClass('is-active');
		$(this)
			.siblings()
			.slideToggle('fast');
	});

	$(`.${componentClass}__close-btn`).on('click', function() {
		$(this)
			.parent()
			.siblings('.js-accordion')
			.click();
	});

	/**
	 * Disable scroll page to #hash on click
	 * Remove Hash from URL when close Accordion
	 **/
	$('.js-accordion_link').click(function(e) {
		const group = $(e.target).closest(`.${componentClass}__group`);
		e.preventDefault();

		let newURL = '';
		if (group.hasClass('is-active')) {
			const pageHashTarget = window.location.hash;
			newURL = window.location.href.replace(`${pageHashTarget}`, '');
		} else {
			newURL = e.currentTarget.href;
		}
		window.history.pushState({ path: newURL }, '', newURL);
	});

	/**
	 * Open accordion if url-hash exists
	 **/
	(function() {
		const anchor = location.hash
			? document.querySelector(location.hash)
			: '';
		if (!anchor) {
			return;
		}
		if (!anchor.closest('[data-module="showAccordion"]')) {
			return;
		}

		const group = $(anchor).closest(`.${componentClass}__group`);
		const link = group.find('.js-accordion_link');

		// Works also with tabs
		const tab = $(group).closest('[data-tab-accordions]');

		if (tab) {
			const tabIndex = tab.attr('data-tab-index');
			$(`[data-tab-index="${tabIndex}"]`).click();
		}

		setTimeout(function() {
			link.click();
		}, 700);
	})();
}

export default showAccordion;
