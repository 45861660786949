// Import necessary dependencies

/**
 * Handles key events to toggle the '.tab_key' class on a fieldset.
 *
 * @param {HTMLFieldSetElement} fieldset - The fieldset element.
 */
export function handleFieldsetFocus(fieldset) {
	let tabPressed = false;

	fieldset.addEventListener('keyup', event => {
		if (event.key === 'Tab' && !tabPressed) {
			fieldset.classList.add('tab_key');
			tabPressed = true;
		}
	});

	fieldset.addEventListener('keydown', event => {
		if (event.key === 'Tab' && tabPressed) {
			fieldset.classList.remove('tab_key');
			tabPressed = false;
		}
	});
}

/**
 * Toggles the '.tab_key' class on fieldsets within a form based on key events.
 *
 * @param {HTMLFormElement} form - The form element.
 */
export function toggleFieldsetClass(form) {
	var fieldsets = document.querySelectorAll('form fieldset');

	if (fieldsets) {
		fieldsets.forEach(fieldset => {
			handleFieldsetFocus(fieldset);
		});
	}
}

/**
 * Appends an Ajax wrapper after an Ajax upload.
 *
 * @param {HTMLFormElement} form - The form element to which the Ajax wrapper will be appended.
 */

export function addAjaxWrapper(form) {
	const ajaxWrapper = jQuery(
		'<div class="ajax-wrapper"><div class="ajax"></div><div class="ajax-full-bg"></div></div>',
	);
	jQuery(form).append(ajaxWrapper);
}
