import Choices from 'choices.js';

function formOrEmbed() {
	const selectElements = document.querySelectorAll('.gform_body select');

	selectElements.forEach(function(element) {
		new Choices(element, {
			searchEnabled: false,
			itemSelectText: '',
			shouldSort: false,
		});
	});
}

document.addEventListener('DOMContentLoaded', function() {
	formOrEmbed(); // Call the formOrEmbed function here
});

export default formOrEmbed;
