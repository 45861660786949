/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

let selector = '.js-hamburger';
let activeClass = 'is-active';
let bodyActiveClass = 'h-scroll-lock nav-active';
let headerActiveClass = 'o-header--hamburger-active';
let navMenu = '.js-slide-out';
let navMenuItem = '.menu-item';
let plusToggle = '.js-nav-drop';
let navMenuActiveClass = 'm-slide-out--active';
let headerSearchButton = 'o-header__search-button';
let searchBar = 'm-search-bar';

export function hamburger() {
	handleInitialClasses();

	// Declaring common variables
	const $navMenu = $(navMenu);
	let isActive;

	// Ensuring slideout can appear on click event
	$(selector).click(function(e) {
		e.preventDefault();
		$(selector).toggleClass(activeClass);

		// Ensuring body cannot be scrolled up and down when the mobile nav is shown
		$('.js-body').toggleClass(bodyActiveClass);
		$('.js-header').toggleClass(headerActiveClass);

		// Makes slide-out appear

		$navMenu.toggleClass(navMenuActiveClass);

		// Set or remove tabindex based on the presence of 'navMenuActiveClass'
		if ($navMenu.hasClass(navMenuActiveClass)) {
			$navMenu.attr('tabindex', 0); // Add tabindex
			$navMenu.find(plusToggle).attr('tabindex', 0); // Add tabindex
		} else {
			$navMenu.removeAttr('tabindex'); // Remove tabindex
			$navMenu.find(plusToggle).removeAttr('tabindex');
		}

		$(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

		if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
			$(selector)
				.parent()
				.siblings(headerSearchButton)
				.trigger('click');
		}

		let navMenuElement = document.querySelector(navMenu);
		navMenuElement.addEventListener('keydown', handleTabKey);

		toggleNavActiveClass(navMenuElement);
	});

	// Find the first tabbable element inside the navMenu
	let firstTabbable = $navMenu.find(
		'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
	)[0];

	// Ensuring slideout nav menu is next element focused after hamburger toggle button
	$(selector).keydown(function(e) {
		if (e.key === 'Tab') {
			isActive = $navMenu.hasClass(navMenuActiveClass);

			if (isActive) {
				e.preventDefault(); // Prevent the default tab behavior

				if (firstTabbable) {
					firstTabbable.focus(); // Focus on the first tabbable element
				}
			}
		}
	});

	// Ensuring focus shifts back to hamburger toggle button when going back
	$(firstTabbable).keydown(function(e) {
		if (e.shiftKey && e.key === 'Tab') {
			isActive = $navMenu.hasClass(navMenuActiveClass);
			const $selector = $(selector);

			if (isActive && document.activeElement === firstTabbable) {
				e.preventDefault();
				$selector[0].focus();
			}
		}
	});

	$(window).on('resize', function() {
		if ($(window).width() >= 992) {
			if ($('.header__hamburger').hasClass('is-active')) {
				$('.header__hamburger').trigger('click');
			}
		}
	});
}

export function hamburgerClose() {
	$(selector).removeClass(activeClass);
	$('.js-body').removeClass(bodyActiveClass);
	$('.js-header').removeClass(headerActiveClass);
	$(navMenu).removeClass(navMenuActiveClass);
}

/**
 * Handles key events to toggle the '.tab_key' class on a navMenuElements.
 *
 * @param {HTMLElement} menuElement - The menu element.
 */
export function handleNavItemFocus(menuElement) {
	let tabPressed = false;
	menuElement.addEventListener('keyup', event => {
		if (event.key === 'Tab' && !tabPressed) {
			menuElement.classList.add('tab_key');
			tabPressed = true;
		}
	});
	menuElement.addEventListener('keydown', event => {
		if (event.key === 'Tab' && tabPressed) {
			menuElement.classList.remove('tab_key');
			tabPressed = false;
		}
	});
}

/**
 * Toggles the '.tab_key' class on navMenuElements within a menu on key events.
 *
 * @param {HTMLElement} navMenuElement - The menu element.
 */
export function toggleNavActiveClass(navMenuElement) {
	var menuElements = navMenuElement.querySelectorAll(
		`${navMenu} a${navMenuItem}, ${navMenu} ${plusToggle}, ${navMenu} .m-slide-out__btn, ${navMenu} .m-crdmedia-3__btn`,
	);
	if (menuElements) {
		menuElements.forEach(menuElement => {
			handleNavItemFocus(menuElement);
		});
	}
}

/**
 * Tracks last tabbed menu item element or submenu plus toggler and ensures when either of them is tabbed focus comes back to hamburger element which toggles slide out menu.
 */
export function handleTabKey(e) {
	const tabKey = e.key === 'Tab';
	const shiftKey = e.shiftKey;
	const manuItemsMainLevel = document.querySelectorAll(
		`${navMenu} li.m-nav__item--level-0`,
	);
	const menuItems = document.querySelectorAll(`${navMenu} ${navMenuItem}`);
	const plusToggles = document.querySelectorAll(`${navMenu} ${plusToggle}`);
	const slideOutBtns = document.querySelectorAll(
		`${navMenu} .m-slide-out__btn`,
	);
	const cardMediaBtns = document.querySelectorAll(
		`${navMenu} .m-crdmedia-3__btn`,
	);

	// Grabbing last items
	const lastMainLevelItem = manuItemsMainLevel[manuItemsMainLevel.length - 1];
	const lastMenuItem = menuItems[menuItems.length - 1];
	const lastPlusToggle = plusToggles[plusToggles.length - 1];
	const lastSlideOutBtn = slideOutBtns.length
		? slideOutBtns[slideOutBtns.length - 1]
		: null;
	const lastCardMediaBtn = cardMediaBtns.length
		? cardMediaBtns[cardMediaBtns.length - 1]
		: null;

	// Check if the active element is inside the last manuItemsMainLevel list item
	const isInsideLastMainLevelItem = lastMainLevelItem.contains(
		document.activeElement,
	);

	// Giving focus to hamburger after last item
	if (tabKey && !shiftKey) {
		// If there are only menu items
		if (!slideOutBtns.length && !cardMediaBtns.length) {
			// Actions to be performed when inside the lastMainLevelItem
			if (isInsideLastMainLevelItem) {
				// Actions to be performed when inside the lastMainLevelItem
				if (
					document.activeElement === lastMenuItem &&
					!lastPlusToggle.classList.contains('tab_key')
				) {
					e.preventDefault();
					document.querySelector(selector).focus();
				}
				if (
					document.activeElement === lastPlusToggle &&
					!lastPlusToggle.classList.contains('is-active')
				) {
					e.preventDefault();
					document.querySelector(selector).focus();
				}
			}
		} else {
			// If there are slideoutbuttons
			if (lastSlideOutBtn && !lastCardMediaBtn) {
				if (document.activeElement === lastSlideOutBtn) {
					e.preventDefault();
					document.querySelector(selector).focus();
				}
				// If there are slideoutbuttons media card buttons
			} else if (lastCardMediaBtn) {
				if (document.activeElement === lastCardMediaBtn) {
					e.preventDefault();
					document.querySelector(selector).focus();
				}
			}
		}
	}
}

/**
 * Makes sure plus toggle has active classes set for current page right away after landing on the page.
 */
function handleInitialClasses() {
	$(navMenuItem).each(function() {
		const $currentNavItem = $(this);
		const $navMenu = $currentNavItem.closest(navMenu);

		// Check if the current nav item has either "current_page_ancestor" or "current-menu-ancestor" (custom post type pages or links in menu recieve "current-menu-ancestor" class)
		if (
			($currentNavItem.is('a.current_page_ancestor') ||
				$currentNavItem.is('a.current-menu-ancestor')) &&
			$navMenu.length
		) {
			// Check if the current nav item has either "current_page_ancestor" or "current-menu-ancestor" as a class (custom post type pages or links in menu recieve "current-menu-ancestor" class)
			if (
				$currentNavItem.hasClass('current_page_ancestor') ||
				$currentNavItem.hasClass('current-menu-ancestor')
			) {
				const $plusToggle = $currentNavItem.siblings(plusToggle);
				if (
					!$plusToggle.hasClass('is-active') &&
					!$plusToggle.hasClass('m-nav__toggle-wrapper--active')
				) {
					$plusToggle.addClass(
						'm-nav__toggle-wrapper--active is-active',
					);
					$plusToggle
						.children('.js-plus-toggle')
						.addClass('a-plus-toggle--opened');
				}
			}
		}
	});
}
