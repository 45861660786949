/**
 * Update ARIA-required attributes for specified input types in Gravity Forms.
 * This function adds `aria-required="false"` to input elements that do not
 * have `aria-required="true"`.
 * Needed for WCAG.
 */
const gformsWCAG = () => {
	// Define an array of input types to target
	const inputTypes = ['text', 'tel', 'email'];

	// Create a selector string that targets all specified input types
	const selector = inputTypes
		.map(type => `.gform_wrapper input[type="${type}"]`)
		.join(', ');

	// Select and process the inputs
	const inputs = document.querySelectorAll(selector);

	inputs.forEach(input => {
		if (
			!input.hasAttribute('aria-required') ||
			input.getAttribute('aria-required') !== 'true'
		) {
			input.setAttribute('aria-required', 'false');
		}
	});
};

export default gformsWCAG;
