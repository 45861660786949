/**
 * PictureFill + LazySizes + WOW
 *
 * NOTE: You should not need to modify
 * this file!
 *
 * The libraries listed below are used in almost ALL DOD projects.
 */

/**
 * Responsive Images Polyfill
 */
import 'picturefill';
/**
 * Lazyload images:
 * @see https://github.com/aFarkas/lazysizes
 */
import 'lazysizes';

/**
 *  Animate On Scroll. Animations are being applied as you scroll down
 */
// import AOS from 'aos';
// import 'aos/dist/aos.css';
/**
 *  Animate On Scroll responsive! With this module you can ovveride the attributes of AOS as you would do it in CSS
 */
import AOS from '@dartmoon/aos-responsive';
/**
 * Reveal CSS animation as a user scrolls down a page: https://github.com/matthieua/WOW
 */
import WOW from 'wow.js/dist/wow.js';

/**
 * Get env variables. Used for development debugging.
 */
const { DEV_MODE_DISABLE_WOW } = process.env;

if (!DEV_MODE_DISABLE_WOW || !parseInt(DEV_MODE_DISABLE_WOW)) {
	/**
	 * Init WOW. Accepts parameters
	 */
	new WOW().init();
}

/**
 * Init AOS
 */
AOS.init({
	mirror: false,
	once: true, // whether animation should happen only once - while scrolling down
	duration: 900, // values from 0 to 3000, with step 50ms
});
