function widgetTranslationExternalToggler() {
	// Language Widget External Toggler Click Trigger

	var widget = document.querySelector('.js-widget-translation');
	var toggler = '.js-widget-translation-toggler';
	var external_toggler = '.js-widget-translation-external-toggler';

	$(external_toggler).on('click keydown', function(event) {
		// Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
		if (
			event.type === 'click' ||
			event.which === 13 ||
			event.keyCode === 13 ||
			event.key === 'Enter'
		) {
			if (
				event.type === 'keydown' &&
				event.key !== 'Enter' &&
				event.which !== 13 &&
				event.keyCode !== 13
			) {
				return; // If it's a keydown event but not the Enter key, exit the function
			}

			if (widget) {
				const toggler = widget.querySelector(
					'.js-widget-translation-toggler',
				);
				// Simulate a click on the toggler if it exists

				if (toggler) {
					toggler.click();
				}
			}
		}
	});
}

export default widgetTranslationExternalToggler;
