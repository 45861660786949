import MicroModal from 'micromodal';

import {
	addAjaxWrapper,
	toggleFieldsetClass,
} from '../../../../js/vendors/gforms/gforms-shared-functions.js';

/**
 * Initializes the modal and handles the click event on a specific link.
 */
const initModalAndHandleClick = () => {
	// Get the current URL
	let currentUrl = new URL(window.location.href);

	/**
	 *  Function to check if the page URL has the 'form' parameter and trigger the click
	 */
	function checkAndTriggerFormLink() {
		const formParamValue = currentUrl.searchParams.get('form');

		if (formParamValue) {
			// Find the corresponding link with href="#formParamValue"
			const formLink = $(`a[href="#${formParamValue}"]`);

			if (formLink.length) {
				// Trigger a click on the link
				setTimeout(() => {
					formLink.trigger('click');
				}, 1000);
			}
		}
	}

	/**
	 * Handles click event on the specified link.
	 */
	function handleClick(e) {
		e.preventDefault();

		const targetModal = $(this).attr('href');
		const modalId = targetModal.replace('#', '');

		showAndInitializeModal(modalId);

		const ajaxForm = $('.ajax-form');
		const formId = ajaxForm.attr('data-id');

		if (formId) {
			makeAjaxRequest(formId, ajaxForm);
		}
	}

	/**
	 * Shows and initializes the modal.
	 */
	function showAndInitializeModal(modalId) {
		MicroModal.show(modalId, {
			onShow: () => {
				jQuery('body').addClass('l-body--modal-open');
				updateUrlWithModalParam(modalId);
			},
			onClose: () => {
				jQuery('body').removeClass('l-body--modal-open');
				removeModalParamFromUrl();
			},
			disableScroll: true,
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
		});

		checkFirstPage();
	}

	/**
	 * Updates the URL with the modal parameter.
	 */
	function updateUrlWithModalParam(modalId) {
		currentUrl.searchParams.set('form', modalId);
		window.history.replaceState({}, '', currentUrl.href);
	}

	/**
	 * Removes the modal parameter from the URL.
	 */
	function removeModalParamFromUrl() {
		currentUrl.searchParams.delete('form');
		window.history.replaceState({}, '', currentUrl.href);
	}

	/**
	 * Checks the first page for required inputs and enables the next button accordingly.
	 */
	function checkFirstPage(ajaxForm) {
		const gformPage = $('.gform_page').first();

		if (gformPage.length) {
			const requiredFieldset = gformPage.find(
				'.gfield_contains_required',
			);

			if (requiredFieldset.length > 0) {
				const allInputs = requiredFieldset.find('input');
				gformPage.find('.gform_next_button').prop('disabled', true);

				allInputs.on('change', function() {
					if ($(this).prop('checked')) {
						ajaxForm
							.find('.gform_next_button')
							.prop('disabled', false);
					}
				});
			}
		}
	}

	/**
	 * Remove Preloader
	 */
	function removePreloader() {
		jQuery('.js-preloader').remove();
	}

	/**
	 * Makes an AJAX request to load form content.
	 */
	function makeAjaxRequest(formId, ajaxForm) {
		jQuery.get(
			`/wp-admin/admin-ajax.php?action=gravity_form_ajax_loading&form_id=${formId}`,
			response => {
				ajaxForm
					.html(response)
					.fadeIn()
					.removeAttr('data-id')
					.addClass('form-loaded');
				removePreloader();
				checkFirstPage(ajaxForm);
				addAjaxWrapper(jQuery(ajaxForm).find('form'));
				toggleFieldsetClass(ajaxForm);
			},
		);
	}

	// Checking if url has form parameter
	checkAndTriggerFormLink();

	// Handle click event on the specified link
	jQuery('a[href="#eligibility-tool"]').on('click', handleClick);
};

// Export the function for external use
export default initModalAndHandleClick;
