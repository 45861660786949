/**
 * Initializes input fields with character limits and manages button states based on input completeness.
 * Applies character limits, sets up input and focus event handlers, and checks initial button state.
 */
function gformsCharacterLimit() {
	applyCharacterLimit('js-pattern-lrn', 13);
	applyCharacterLimit('js-pattern-ssin', 10);
}

/**
 * Configures event listeners for input elements to enforce character limits and manage UI interactions,
 * such as cursor behavior and button enable/disable state.
 * @param {string} className - The class name of the input elements to be managed.
 * @param {number} maxLength - The maximum number of digits the input should accept.
 */
function applyCharacterLimit(className, maxLength) {
	const elements = document.querySelectorAll(`.${className}`);
	elements.forEach(element => {
		if (element.tagName.toLowerCase() === 'input') {
			element.maxLength = maxLength;

			element.addEventListener('input', function(e) {
				toggleButtonState(e.target, maxLength);
			});

			element.addEventListener('paste', function(e) {
				e.preventDefault(); // Prevent the default paste behavior

				const clipboardData = e.clipboardData || window.clipboardData;
				let pastedData = clipboardData.getData('Text');

				// Keep only the first maxLength characters and digits
				pastedData = pastedData.replace(/\D/g, '').slice(0, maxLength);

				// Set the value
				element.value = pastedData;

				// Move the cursor to the beginning of the input field
				element.setSelectionRange(0, 0);

				toggleButtonState(element, maxLength);
			});

			// Initial state setup
			toggleButtonState(element, maxLength);
		}
	});

	// Check if the jQuery Mask plugin is available
	if (jQuery.fn.mask) {
		// Apply mask using jQuery
		const maskPattern = '9'.repeat(maxLength); // Create a string of '9' repeated maxLength times
		jQuery(`.${className}`)
			.mask(maskPattern, { autoclear: false })
			.on('keyup', function(e) {
				toggleButtonState(this, maxLength);
			});
	} else {
		console.warn(
			'jQuery Mask plugin is not available. Mask functionality will be skipped.',
		);
	}
}

/**
 * Toggles the disabled state of the 'Next' button based on whether the input has reached the required maxLength.
 * @param {HTMLElement} inputElement - The input element being checked.
 * @param {number} maxLength - The required number of digits for the input to be considered complete.
 */
function toggleButtonState(inputElement, maxLength) {
	// Clean the value to consider only numeric characters
	let cleanValue = inputElement.value.replace(/\D+/g, '');

	let isComplete = cleanValue.length === maxLength;

	// Find the closest form page
	let formPageElement = inputElement.closest('.gform_page');
	if (!formPageElement) {
		return;
	}

	// Find the 'Next' button
	let nextButton = formPageElement.querySelector('.gform_next_button');
	if (!nextButton) {
		return;
	}

	// Toggle the button state based on input completeness
	if (isComplete) {
		nextButton.removeAttribute('disabled');
	} else {
		nextButton.setAttribute('disabled', 'disabled');
	}
}

// Initialize the functionality once the DOM is fully loaded.
document.addEventListener('DOMContentLoaded', function() {
	gformsCharacterLimit();
});

// Reinitialize the functionality when a new form page is loaded via AJAX.
jQuery(document).on('gform_page_loaded', function(
	event,
	form_id,
	current_page,
) {
	gformsCharacterLimit();
});

// Reapply the functionality after a form is rendered via AJAX.
jQuery(document).on('gform_post_render', function(event, form_id) {
	gformsCharacterLimit();
});

export default gformsCharacterLimit;
