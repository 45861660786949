import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';

import tippy from 'tippy.js';

function gformsTooltip() {
	tippy('.js_gf_tooltip_buton', {
		trigger: 'click',
		allowHTML: true,
		placement: 'bottom',
		theme: 'green',
		offset: [-100, 15],
		interactive: true,
		onShow(instance) {
			const button = instance.popper.querySelector(
				'.js_gf_tooltip_close',
			);
			button.addEventListener('click', () => {
				instance.hide();
			});
		},
	});
}

document.addEventListener('DOMContentLoaded', function() {
	gformsTooltip();
});

/* Reinit for AJAX Multiple Pages */
jQuery(document).on('gform_page_loaded', function(
	event,
	form_id,
	current_page,
) {
	gformsTooltip();
});

/* Reinit after form appears via AJAX */
jQuery(document).on('gform_post_render', function(event, form_id) {
	gformsTooltip();
});

export default gformsTooltip;
