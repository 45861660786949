/**
 * Scrolls to the top of the page when the scroll-to-top button is clicked.
 *
 * @param {Object} options - The options for configuring the scroll-to-top functionality.
 * @param {string} options.selector - The selector for the scroll-to-top button.
 * @param {string} options.activeClass - The class to add when the button becomes active.
 * @param {number} options.activeDistance - The scroll distance from the top at which the button becomes active.
 */
function scrollToTop(options = {}) {
	const {
		selector = '.js-scroll-to-top',
		activeClass = 'a-scroll-to-top--active',
		activeDistance = 200,
	} = options;

	/**
	 * Handles scroll events and adds/removes the active class from the scroll-to-top button.
	 */
	function handleScroll() {
		if (window.scrollY > activeDistance) {
			document.querySelector(selector)?.classList.add(activeClass);
		} else {
			document.querySelector(selector)?.classList.remove(activeClass);
		}
	}

	/**
	 * Scrolls the page to the top when the button is clicked.
	 *
	 * @param {Event} event - The click event.
	 */
	function scrollToTopHandler(event) {
		event.preventDefault();
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	// Add scroll event listener to window
	window.addEventListener('scroll', handleScroll);

	// Add click event listener to the scroll-to-top button
	const scrollButton = document.querySelector(selector);
	if (scrollButton) {
		scrollButton.addEventListener('click', scrollToTopHandler);
	}
}

export default scrollToTop;
