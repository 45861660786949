/**
 * Handles FAQ Tabs functionality
 */
function faqTabs() {
	const hooks = {
		title: '[data-tab-title]',
		accordion: '[data-tab-accordions]',
		titleActive: 'data-tab-title-active',
		accordionActive: 'data-tab-accordions-active',
		index: 'data-tab-index',
	};

	const titles = document.querySelectorAll(hooks.title);

	if (!titles) {
		return;
	}

	titles.forEach(el => {
		const clickHandler = () => {
			hideActiveTab();

			const tabIndex = el.dataset.tabIndex;

			el.setAttribute(hooks.titleActive, '');

			const relatedTab = document.querySelector(
				`${hooks.accordion}[${hooks.index}="${tabIndex}"]`,
			);

			relatedTab.setAttribute(hooks.accordionActive, '');
			reinitAccordionStyles(relatedTab);
		};

		el.addEventListener('click', clickHandler);

		el.addEventListener('keydown', event => {
			if (event.key === 'Enter') {
				clickHandler();
			}
		});
	});

	/**
	 * Hides active tab elements
	 */
	function hideActiveTab() {
		document
			.querySelector(`[${hooks.titleActive}]`)
			?.removeAttribute('data-tab-title-active');

		document
			.querySelector(`[${hooks.accordionActive}]`)
			?.removeAttribute('data-tab-accordions-active');
	}

	/**
	 * Updates styles for accordion elements
	 * @param {Element} relatedTab - The related tab element
	 */
	function reinitAccordionStyles(relatedTab) {
		const accordionGroups = relatedTab.querySelectorAll(
			'.m-accordion__group',
		);

		accordionGroups.forEach(accordionGroup => {
			if (
				getComputedStyle(accordionGroup).visibility === 'hidden' &&
				accordionGroup.classList.contains('wow')
			) {
				accordionGroup.style.opacity = '1';
				accordionGroup.style.visibility = 'visible';
			}
		});
	}
}

export default faqTabs;
