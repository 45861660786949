import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';

import tippy from 'tippy.js';

function cardsTooltip() {
	tippy('.js_tooltip_buton', {
		trigger: 'click',
		allowHTML: true,
		placement: 'bottom',
		theme: 'green',
		offset: [-100, 15],
		interactive: true,
		onShow(instance) {
			const button = instance.popper.querySelector('.js_tooltip_close');
			button.addEventListener('click', () => {
				instance.hide();
			});

			button.addEventListener('keydown', event => {
				if (event.key === 'Enter') {
					event.preventDefault();
					instance.hide();
				}
			});
		},
	});
}

document.addEventListener('DOMContentLoaded', function() {
	cardsTooltip();
});

export default cardsTooltip;
