function widgetTranslation() {
	// Language Widget Blocks

	var widget = '.js-widget-translation';
	var toggler = '.js-widget-translation-toggler';
	var external_toggler = '.js-widget-translation-external-toggler';

	$(toggler).on('click keydown', function(event) {
		// Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
		if (
			event.type === 'click' ||
			event.which === 13 ||
			event.keyCode === 13 ||
			event.key === 'Enter'
		) {
			if (
				event.type === 'keydown' &&
				event.key !== 'Enter' &&
				event.which !== 13 &&
				event.keyCode !== 13
			) {
				return; // If it's a keydown event but not the Enter key, exit the function
			}

			var widget_obj = $(this).closest(widget);
			var external_toggler_obj = document.querySelectorAll(
				external_toggler,
			);

			const languageSwitchersBlocks = widget_obj.find(
				'.js-google-translate-switcher',
			);

			if (widget_obj.hasClass('active')) {
				widget_obj.removeClass('active');
				if (external_toggler_obj) {
					external_toggler_obj.forEach(function(toggler) {
						toggler.classList.remove('active'); // Removing class from all external togglers
					});
				}

				// Removing tabindex attribute from languages so that they are not tabbable when widget is closed
				languageSwitchersBlocks.each(function() {
					const anchorTags = this.querySelectorAll('a');
					anchorTags.forEach(tag => {
						tag.removeAttribute('tabindex');
					});
				});
			} else {
				widget_obj.addClass('active');
				if (external_toggler_obj) {
					external_toggler_obj.forEach(function(toggler) {
						toggler.classList.add('active'); // Adding class to all external togglers
					});
				}

				// Making sure languages receive tabindex attribute when widget is open in order to access them with keyboard
				languageSwitchersBlocks.each(function() {
					const anchorTags = this.querySelectorAll('a');
					anchorTags.forEach(tag => {
						tag.setAttribute('tabindex', '0');
					});
				});
			}
		}
	});

	$(document).on('click', function(e) {
		if (
			!$(e.target).closest(external_toggler).length &&
			!$(e.target).closest(widget).length &&
			$(widget).hasClass('active')
		) {
			$(widget).removeClass('active');
			$(external_toggler).removeClass('active');
		}
	});

	const languageSwitchersBlocks = document.querySelectorAll(
		`.js-google-translate-switcher`,
	);

	if (languageSwitchersBlocks.length) {
		// Looping through all of them (we can have several of them)
		[...languageSwitchersBlocks].map(languageSwitcherBlock => {
			// Adding click and keydown listeners
			languageSwitcherBlock.addEventListener(
				'click',
				handleLanguageSwitch,
			);
			languageSwitcherBlock.addEventListener(
				'keydown',
				handleLanguageSwitch,
			);
		});
	}

	function handleLanguageSwitch(event) {
		// Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
		if (
			event.type === 'click' ||
			event.which === 13 ||
			event.keyCode === 13 ||
			event.key === 'Enter'
		) {
			if (
				event.type === 'keydown' &&
				event.key !== 'Enter' &&
				event.which !== 13 &&
				event.keyCode !== 13
			) {
				return; // If it's a keydown event but not the Enter key, exit the function
			}

			const target = event.target;
			const button = target.closest('a');

			if (!button) {
				return;
			}

			event.preventDefault();

			const chosenLanguage = button.getAttribute('data-href');
			if (!chosenLanguage) {
				return;
			}

			const googleTranslateSelect = document.querySelector(
				'.goog-te-combo',
			);
			if (!googleTranslateSelect) {
				return;
			}

			googleTranslateSelect.value = chosenLanguage;
			googleTranslateSelect.dispatchEvent(new Event('change'));

			updateURLLanguage(chosenLanguage);
		}
	}

	/**
	 * Updates the 'lang' query parameter of the URL if the current pathname includes '/seniors'.
	 * This function is primarily used to facilitate language switching on senior-specific pages.
	 * @author Dreamers of Day
	 * @param {string} lang - The language code to set in the URL.
	 */
	function updateURLLanguage(lang) {
		const url = new URL(window.location.href); // Create a URL object from the current location

		if (url) {
			url.searchParams.set('lang', lang); // Set the 'lang' search parameter
			window.history.pushState({}, '', url.href); // Update the browser history state
		}
	}

	/**
	 * Sets the language preference based on the 'lang' query parameter and updates the 'googtrans' cookie.
	 * It also marks the currently active language link. This function assumes language links are marked with
	 * `data-href` attributes that match language codes and are found within elements with the class
	 * 'js-google-translate-switcher'.
	 * @author Dreamers of Day
	 */
	function setLanguage() {
		const languageLinks = document.querySelectorAll(
			'.js-google-translate-switcher a',
		);
		const url = new URL(window.location.href);
		const lang = url.searchParams.get('lang'); // Extract the 'lang' parameter value

		// Check if lang parameter exists and there are language links available
		if (lang && languageLinks.length) {
			languageLinks.forEach(link => {
				if (link.getAttribute('data-href') === lang) {
					// Reset 'googtrans' cookie
					document.cookie =
						'googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
					document.cookie = `googtrans=/en/${lang}; path=/`; // Set new 'googtrans' cookie

					// Simulate clicking the link and mark it as active
					link.click();
					link.classList.add('active');
				}
			});
		}
	}

	// Initialize the language settings based on URL parameters
	setLanguage();
}

export default widgetTranslation;
