import { addAjaxWrapper, toggleFieldsetClass } from './gforms-shared-functions';

/**
 * Finds forms with enctype 'multipart/form-data' and attaches 'Previous' button to the heading.
 * Also moves elements with class 'content-bottom' to the beginning of the .gform_page_footer.
 */
const gformsMultiplePrevious = () => {
	/**
	 * Checks each form.
	 */
	function gformPageChecker() {
		const forms = document.querySelectorAll('form');

		forms.forEach(checkGform);
	}

	/**
	 * Checks a form based on the enctype.
	 */
	function checkGform(form) {
		const enctype = form.getAttribute('enctype');

		if (enctype === 'multipart/form-data') {
			const gformPages = form.querySelectorAll('.gform_page');
			gformPages.forEach(gformPage => {
				checkGformPage(form, gformPage);
			});
		}
	}

	/**
	 * Checks a Gravity Form page for various conditions.
	 */
	function checkGformPage(form, gformPage) {
		const displayStyle = getComputedStyle(gformPage).display;

		if (displayStyle !== 'none') {
			const [, formId, pageNumber] =
				gformPage.id.match(/gform_page_(\d+)_(\d+)/) || [];

			if (formId && pageNumber) {
				handleResetButtonVisibility(form, pageNumber);
			}

			handlePreviousButton(gformPage);
			handleHideLastSteps(gformPage, form);
			checkConditions(gformPage);
			inputChangesListener(gformPage);
			changeNextButton(gformPage);
			moveElementToBottom(gformPage);
			resetButtonListener(form);
			addAjaxWrapper(form);
			toggleFieldsetClass(form);
		}
	}

	/**
	 * Handles the visibility of the reset button based on the page number.
	 */
	function handleResetButtonVisibility(form, pageNumber) {
		if (parseInt(pageNumber, 10) !== 1) {
			addResetButton(form);
		} else {
			removeResetButton(form);
		}
	}

	/**
	 * Handles the previous button functionality.
	 */
	function handlePreviousButton(gformPage) {
		const prevButton = gformPage.querySelector('.gform_previous_button');

		if (prevButton) {
			const previousButton = createPreviousButton(gformPage);
			addClickListenerToPrevButton(previousButton, prevButton, gformPage);
		}
	}

	/**
	 * Creates a new previous button element with tabindex and role attribute.
	 */
	function createPreviousButton(gformPage) {
		const gformWrapper = gformPage.closest('.gform_wrapper');
		const gformHeading = gformWrapper.querySelector('.gform_heading');
		const previousButton = document.createElement('div');
		previousButton.className = 'js_previous';
		previousButton.tabIndex = 0;
		previousButton.setAttribute('role', 'button');
		gformHeading.appendChild(previousButton);

		return previousButton;
	}

	/**
	 * Adds click and keydown event listeners to the previous button.
	 */
	function addClickListenerToPrevButton(
		previousButton,
		prevButton,
		gformPage,
	) {
		/**
		 * Handles the click event on the previous button.
		 */
		function handleClick() {
			handleButtonClick();
		}

		/**
		 * Handles the keydown event on the previous button.
		 */
		function handleKeydown(event) {
			if (event.key === ' ' || event.key === 'Enter') {
				event.preventDefault(); // Prevent scrolling when using Space key
				handleButtonClick();
			}
		}

		/**
		 * Handles the click or keydown event on the previous button.
		 */
		function handleButtonClick() {
			if (gformPage) {
				// Find all checkboxes and radio buttons within the fieldset
				var checkboxesAndRadios = gformPage.querySelectorAll(
					'input[type="checkbox"], input[type="radio"]',
				);

				if (checkboxesAndRadios) {
					// Uncheck each checkbox and radio button
					checkboxesAndRadios.forEach(function(input) {
						input.checked = false;
					});
				}
			}

			// Moving back
			prevButton.click();
		}

		// Add event listeners to the previous button
		previousButton.addEventListener('click', handleClick);
		previousButton.addEventListener('keydown', handleKeydown);
	}

	/**
	 * Handles hiding last steps based on visibility conditions.
	 */
	function handleHideLastSteps(gformPage, form) {
		const hideLastSteps = gformPage.querySelector('.hide-last-steps');

		if (
			hideLastSteps &&
			hideLastSteps.classList.contains('gfield_visibility_visible')
		) {
			hideStepsAfterActiveStep(form);
		}
	}

	/**
	 * Hides steps after the active step.
	 */
	function hideStepsAfterActiveStep(form) {
		const activeStep = form.querySelector('.gf_page_steps .gf_step_active');

		if (activeStep) {
			// Find all steps after the active step
			const stepsToHide = Array.from(
				activeStep.nextElementSibling.parentElement.children,
			).slice(
				Array.from(
					activeStep.nextElementSibling.parentElement.children,
				).indexOf(activeStep.nextElementSibling),
			);

			// Hide the steps
			stepsToHide.forEach(step => {
				step.style.setProperty('display', 'none', 'important');
			});
		}
	}

	/**
	 * Checks conditions and enables/disables the next button accordingly.
	 */
	function checkConditions(gformPage) {
		// Check radio and checkbox inputs
		var isAnyInputChecked =
			gformPage.querySelectorAll('input:checked').length > 0;
		var isTextInputFilled = false;

		// Check text inputs
		var textInputs = gformPage.querySelectorAll('input[type="text"]');
		for (var i = 0; i < textInputs.length; i++) {
			if (textInputs[i].value.trim() !== '') {
				isTextInputFilled = true;
				break;
			}
		}

		// If the fieldset has the required class and at least one input is checked
		var fieldset = gformPage.querySelector('.gfield_contains_required');
		if (fieldset) {
			if (isAnyInputChecked || isTextInputFilled) {
				// Enable the button
				var nextButton = gformPage.querySelector('.gform_next_button');
				if (nextButton) {
					nextButton.removeAttribute('disabled');
				}
			} else {
				// Disable the button
				var nextButton = gformPage.querySelector('.gform_next_button');
				if (nextButton) {
					nextButton.setAttribute('disabled', 'disabled');
				}
			}
		}
	}

	/**
	 * Adds event listeners for radio, checkbox, and text inputs.
	 */
	function inputChangesListener(gformPage) {
		// Select all radio and checkbox inputs
		var radioAndCheckboxInputs = gformPage.querySelectorAll(
			'input[type="radio"], input[type="checkbox"]',
		);

		// Add event listener for radio and checkbox inputs
		radioAndCheckboxInputs.forEach(function(input) {
			input.addEventListener('change', function() {
				checkConditions(gformPage);
				changeNextButton(gformPage);
			});
		});

		// Select all text inputs
		var textInputs = gformPage.querySelectorAll('input[type="text"]');

		// Add event listener for text inputs
		textInputs.forEach(function(input) {
			input.addEventListener('input', function() {
				checkConditions(gformPage);
				changeNextButton(gformPage);
			});
		});
	}

	/**
	 * Moves an element to the bottom of its container.
	 */
	function moveElementToBottom(gformPage) {
		const moveToBottomElement = gformPage.querySelector('.content-bottom');

		if (moveToBottomElement) {
			const gformPageFooter = gformPage.querySelector(
				'.gform_page_footer',
			);

			if (gformPageFooter) {
				gformPageFooter.insertBefore(
					moveToBottomElement,
					gformPageFooter.firstChild,
				);
			}
		}
	}

	/**
	 * Resets the form, clearing input fields and checkboxes.
	 */
	function gformReset() {
		var form = jQuery('form.eligibility-form');

		// Reset input fields and checkboxes
		form.find('input[type="text"]').val('');
		form.find('input[type="checkbox"]').prop('checked', false);
		form.find('input[type="radio"]').removeAttr('checked');

		// Remove classes from .gf_step elements
		form.find('.gf_step').removeClass(
			'gf_step_active gf_step_completed gf_step_previous',
		);

		// Hide all form pages and show the first one
		form.find('.gform_page').hide();
		form.find('.gform_page:eq(0)').show();

		// Initializing return to first step

		var firstPage = form.find('div[data-js="page-field-id-1"]');
		var nextButton = firstPage.find('.gform_next_button');

		if (nextButton) {
			nextButton.click();
		}

		// Add .gf_step_active to the first .gf_step element
		var firstStep = form.find('.gf_step_first');
		firstStep.addClass('gf_step_active');

		// Removing previous button
		const gformHeading = document.querySelector(
			'.eligibility-form_wrapper .gform_heading',
		);
		var jsPreviousButton = gformHeading.querySelector('.js_previous');
		if (jsPreviousButton) {
			jsPreviousButton.remove();
		}
	}

	/**
	 * Appends the Reset button to the form.
	 */
	function addResetButton(form) {
		const resetButton = jQuery(
			'<div tabindex="0" role="button" class="button gform_reset_button">Reset</div>',
		);
		jQuery(form).append(resetButton);
	}

	/**
	 * Removes the Reset button from the form.
	 */
	function removeResetButton(form) {
		jQuery(form)
			.find('.gform_reset_button')
			.remove();
	}

	/**
	 * Adds click and keydown event listeners to the Reset button.
	 */
	function resetButtonListener(form) {
		var resetButton = form.querySelector('.gform_reset_button');

		if (resetButton) {
			/**
			 * Handles the click event on the Reset button.
			 */
			function handleClick() {
				gformReset();
			}

			/**
			 * Handles the keydown event on the Reset button.
			 */
			function handleKeydown(event) {
				if (event.key === ' ' || event.key === 'Enter') {
					event.preventDefault(); // Prevent scrolling when using Space key
					gformReset();
				}
			}

			// Add event listeners to the Reset button
			resetButton.addEventListener('click', handleClick);
			resetButton.addEventListener('keydown', handleKeydown);
		}
	}

	/**
	 * Changing Default Next Button to a custom one
	 */
	function changeNextButton(gformPage, form) {
		const changeNextButton = gformPage.querySelector(
			'.change-next-button .a-btn',
		);

		if (changeNextButton) {
			const defaultNextButton = gformPage.querySelector(
				'.gform_page_footer .gform_next_button',
			);
			defaultNextButton.classList.add('hidden');
			if (defaultNextButton !== null) {
				var defaultNextButtonisDisabled = defaultNextButton.disabled;
			}

			if (defaultNextButtonisDisabled) {
				changeNextButton.classList.add('disabled');
			} else {
				changeNextButton.classList.remove('disabled');
			}

			changeNextButton.addEventListener('click', function() {
				defaultNextButton.click();
			});
		}
	}

	// Initial check for form pages
	gformPageChecker();

	// Listen for the gform_page_loaded event
	jQuery(document).on('gform_page_loaded', (event, formId, currentPage) => {
		gformPageChecker();
	});

	gform.addFilter('gform_spinner_target_elem', function($targetElem, formId) {
		return $('.ajax');
	});
};

// Call the function to execute the logic
gformsMultiplePrevious();

export default gformsMultiplePrevious;
