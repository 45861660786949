function scrollDown() {
	$('a[data-module="scrollDown"]').on('click', function(e) {
		e.preventDefault();

		var offest = 0;

		// Get offset from anchor

		var anchor = $(this).attr('href');

		if (anchor && $(anchor).length) {
			offest = $(anchor).offset().top;
		}

		// Get next section offset
		else if (
			$(this).closest('section').length &&
			$(this)
				.closest('section')
				.next('.o-block').length
		) {
			offest = $(this)
				.closest('section')
				.next('.o-block')
				.offset().top;
		} else if (
			$(this).closest('.o-block').length &&
			$(this)
				.closest('.o-block')
				.next('.o-block').length
		) {
			offest = $(this)
				.closest('section')
				.next('.o-block')
				.offset().top;
		}

		// Animate scroll to offset

		$('html, body').animate({ scrollTop: offest }, 700);
	});
}

export default scrollDown;
