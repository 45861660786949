import './util/modernizr';
import './util/polyfills';
import '../style.scss';
import './core/core';

/*
 * Atoms
 */
import floatingCta from '../patterns/01-atoms/buttons/button-floating-cta/button-floating-cta';
import scrollDown from '../patterns/01-atoms/links/scroll-down/scroll-down';
import scrollToTop from '../patterns/01-atoms/misc/scroll-to-top/scroll-to-top';
/*
 * Molecules
 */
import showAccordion from '../patterns/02-molecules/accordions/accordion/accordion';
// import debugControls from '../patterns/02-molecules/admin/debug-controls/debug-controls';
import dropdown from '../patterns/02-molecules/dropdowns/dropdown/dropdown';
import sibWebApiForm from '../patterns/02-molecules/forms/sibwebapi-form/sibwebapi-form';
// import gallery from '../patterns/02-molecules/galleries/gallery/gallery';
// import mapBlock from '../patterns/02-molecules/maps/map/map';
import modal from '../patterns/02-molecules/modals/modal/modal';
// import defaultFilter from '../patterns/03-organisms/default/default-filter/default-filter';
// import defaultSecondaryNavbar from '../patterns/03-organisms/default/default-secondary-navbar/default-secondary-navbar';
// import defaultTeamMembers from '../patterns/03-organisms/default/default-team/default-team';
import modalForm from '../patterns/02-molecules/modals/modal-form/modal-form';
import modalVideo from '../patterns/02-molecules/modals/modal-video/modal-video';
import { hamburger } from '../patterns/02-molecules/nav/hamburger/hamburger';
import navigateCategory from '../patterns/02-molecules/nav/nav-category/nav-category';
import navMob from '../patterns/02-molecules/nav/nav-mobile/nav-mobile';
import searchBar from '../patterns/02-molecules/search/search-bar/search-bar';
import { slideOut } from '../patterns/02-molecules/slide-outs/slide-out/slide-out';
import sliderImages from '../patterns/02-molecules/sliders/slider-images/slider-images';
import sliderTestimonials from '../patterns/02-molecules/sliders/slider-testimonials/slider-testimonials';
import socialShare from '../patterns/02-molecules/social/social-share/social-share';
import tabs from '../patterns/02-molecules/tabs/tabs/tabs';
import widgetTranslation from '../patterns/02-molecules/widgets/widget-translation/widget-translation';
import widgetTranslationExternalToggler from '../patterns/02-molecules/widgets/widget-translation-external-toggler/widget-translation-external-toggler';
import cardsTooltip from '../patterns/03-organisms/cards-2/cards-2/cards-2';
import faqTabs from '../patterns/03-organisms/faq/faq-2/faq-2';
// import cursorEffects from './animations/cursor-effects';
import formOrEmbed from '../patterns/03-organisms/form-or-embed/form-or-embed-1/form-or-embed-1';
/*
 * Organisms
 */
import header from '../patterns/03-organisms/header/header';
import hero4 from '../patterns/03-organisms/heros/hero-4/hero-4';
import hero5 from '../patterns/03-organisms/heros/hero-5/hero-5';
import swiperNotifications from '../patterns/03-organisms/notifications/notifications-1/notifications-1';
import swiperSlider from '../patterns/03-organisms/slider/slider-1/slider-1';
import parallaxScroll from './animations/parallax-light';
import dropdownInit from './misc/dropdown/dropdown-init.js';
import externalLinks from './misc/external-links/external-links';
/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
// import letterEffects from './animations/splittext';
/*
 * Misc
 */
//import scrollToAnchor from './misc/scroll/scroll-to-anchor';
import filter from './misc/filter/filter.js';
import gformsCharacterLimit from './vendors/gforms/gforms-char-limit';
import gformsMultiplePrevious from './vendors/gforms/gforms-multiple';
import gformsTooltip from './vendors/gforms/gforms-tooltip';
import gformsWCAG from './vendors/gforms/gforms-wcag';

/*
 * Plop Entry (do not remove)
 */
////DO NOT ERASE OR PLOP IMPORTING WILL FAIL
////IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
	showAccordion,
	sliderImages,
	sliderTestimonials,
	header,
	hero4,
	hero5,
	swiperNotifications,
	navigateCategory,
	searchBar,
	scrollToTop,
	floatingCta,
	socialShare,
	hamburger,
	slideOut,
	modal,
	// debugControls,
	tabs,
	// mapBlock,
	// defaultTeamMembers,
	// defaultSecondaryNavbar,
	// defaultFilter,
	dropdown,
	// gallery,
	modalForm,
	modalVideo,
	scrollDown,
	sibWebApiForm,
	formOrEmbed,
	swiperSlider,
	faqTabs,
	gformsMultiplePrevious,
	gformsTooltip,
};

/*
 * Fire JS functions by data-module
 */
let calledModulesNames = [];
modules.instantiate = function(elem) {
	const $this = $(elem);
	const module = $this.attr('data-module');

	if (module === undefined) {
		throw 'Module not defined (use data-module="")';
	} else if (module in modules) {
		if ($this.attr('data-initialized') === 'true') {
			return;
		}
		new modules[module](elem);
		$this.attr('data-initialized', true);
	} else {
		throw "Module '" + module + "' not found";
	}
};

jQuery(document).ready(function() {
	//scrollToAnchor($);
	navMob($);
	widgetTranslation($);
	widgetTranslationExternalToggler($);
	externalLinks($);

	dropdownInit();
	filter();
	gformsWCAG();
	gformsCharacterLimit();

	/*
	 * Loops through component functions that should only run if the component exists in the ajax container or page.
	 * The actual check is performed through "data-module";
	 */
	$('[data-module]').each(function() {
		if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
			modules.instantiate(this);

			calledModulesNames.push($(this).data('module'));
		}
	});

	/*
	 * GSAP based animations. Hide if not used.
	 */
	// cursorEffects($);
	// letterEffects($);
	// scrollSkewer($);
});

jQuery(window).scroll(function() {
	parallaxScroll($);
});

console.log('Test CI 2');
